/*
Object.prototype.toString.call(999) // "[object Number]"
Object.prototype.toString.call('') // "[object String]"
Object.prototype.toString.call(Symbol()) // "[object Symbol]"
Object.prototype.toString.call(42n) // "[object BigInt]"
Object.prototype.toString.call(null) // "[object Null]"
Object.prototype.toString.call(undefined) // "[object Undefined]"
Object.prototype.toString.call(true) // "[object Boolean]"
Object.prototype.toString.call({a:1}) // "[object Object]"
Object.prototype.toString.call([1,2]) // "[object Array]"
Object.prototype.toString.call(new Date) // "[object Date]"
Object.prototype.toString.call(function(){}) // "[object Function]"
*/

export function isNull(o) {
    return o === null;
}

export function isUndefined(o) {
    return o === undefined;
}

export let Collection = {
    isNull: (o) => {
        if (isUndefined(o)) return true;
        if (isNull(o)) return true;
        return false;
    },

    isCollection: (o) => {
        let called = Object.prototype.toString.call(o);
        return called !== "[object Array]"
    },

    isEmpty: (o) => {
        if (Collection.isNull(o)) return true;
        if (!Collection.isCollection(o)) {
            // throw exception
        }
        return o.length === 0;
    }
}

export function formatDate(timestamp) {
    if (timestamp === undefined || timestamp === '') {
        return '';
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    if (result.search('Nan') > -1) return '';
    return result;
}
