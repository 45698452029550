import Dexie from 'dexie';

export let SUPPORT_INDEXEDDB = false;
if (window.indexedDB) {
    SUPPORT_INDEXEDDB = true;
} else {
    SUPPORT_INDEXEDDB = false;
}

function ClientDatabase() {
    const db = new Dexie('Client');

    db.version(1).stores({
        client: '++id, *createTime, *clientId',
    });

    const table = {
        client: {
            id: null,
            createTime: null,
            clientId: null
        }
    }

    /**
     * 获取客户端列表
     * @returns {Promise<*>}
     */
    this.list = async () => {
        return await db.client.toArray();
    }

    /**
     * 保存客户端id，防止变更
     * @param clientId 客户端 id
     * @returns {Promise<void>}
     */
    this.save = async (clientId) => {
        if (await this.checkIfExist(clientId)) {

        } else {
            let gmtC = new Date().getTime();
            db.client.add({
                createTime: gmtC,
                clientId: clientId
            });
        }
    }

    /**
     * 检查客户端是否已存在
     * @param clientId 客户端 id
     * @returns {Promise<boolean>}
     */
    this.checkIfExist = async (clientId) => {
        let entityList = await db.client
            .where('clientId').equals(clientId)
            .toArray();
        return entityList !== null && entityList.length > 0;
    }

}

export const client = new ClientDatabase();
