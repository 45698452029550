import Dexie from 'dexie';


export const db = new Dexie('Jusic');
export let SUPPORT_INDEXEDDB = false;
export let CHAT_MESSAGE_EXPIRE_ENABLED = false;
export let CHAT_MESSAGE_MAX_NUMBER = 1000;
if (window.indexedDB) {
    SUPPORT_INDEXEDDB = true;
} else {
    SUPPORT_INDEXEDDB = false;
}

db.version(1).stores({
    chat: '++id, *createTime, *sessionId, *nickName, *type, *content, *raw, *clientId, *roomId',
});

const table = {
    chat: {
        id: null,
        createTime: null,
        sessionId: null,
        nickName: null,
        type: null,
        content: null,
        raw: null,
        clientId: null,
        roomId: null
    }
}

let chatRepository = {
    list: async () => {
        return await db.chat.toArray();
    },

    listByUserId: async (userId) => {
        return db.chat.where('userId').equals(userId).toArray();
    },

    getById: async (id) => {
        return await db.chat.get(id);
    },

    save: async (entity) => {
        // let no = chatRepository.convertToString(generate());
        let gmtC = new Date().getTime();
        await chatRepository.deleteOld();

        db.chat.add({
            createTime: gmtC,
            sessionId: entity.sessionId,
            nickName: entity.nickName,
            type: entity.type,
            content: entity.content,
            raw: entity.raw,
            clientId: entity.clientId,
            roomId: entity.roomId,
        });
    },

    deleteOld: async () => {
        if (!CHAT_MESSAGE_EXPIRE_ENABLED) {
            // message expire policy is not enabled.
            // log.debug("[chat-repository] chat message expire policy is not enabled.")
        } else {
            let count = await chatRepository.count();
            let overflowTimes = count - CHAT_MESSAGE_MAX_NUMBER;
            // log.debug(`[chat-repository] delete old data; data count: ${count}, overflow times: ${overflowTimes}`);
            if (overflowTimes > 0) {
                for (let i = 0; i < overflowTimes; i++) {
                    let item = await db.chat.orderBy('id').first();
                    if (item) {
                        await chatRepository.deleteById(item.id);
                        // log.debug(`[chat-repository] delete old data; delete time: ${i + 1}; id: ${item.id}`, item);
                    }
                }
            }
        }
    },

    deleteById: async (id) => {
        db.chat.delete(id);
    },

    load: async (page, size) => {
        if (page === null || size === null) {
            return chatRepository.loadAll();
        } else {
            let offset = (page - 1) * size;
            // log.debug(`[db-load]: page: ${page}, size: ${size}, offset: ${offset}`);
            return db.chat.orderBy('id').reverse().offset(offset).limit(size).toArray();
        }
    },

    loadAll: async () => {
        return await db.chat.toArray();
    },

    loadBeforeId: async (id, size) => {
        if (size === null) size = 10;
        if (id !== undefined && id !== null) {
            // log.debug(`[db-load]: loadBeforeId; id: ${id}, size: ${size}`);
            return db.chat.where('id').below(id).reverse().limit(size).toArray();
            // return db.chat.where('id').above(id).limit(size).toArray();
        } else {
            // log.debug(`[db-load]: loadBeforeId; size: ${size}`);
            return db.chat.orderBy('id').reverse().limit(size).toArray();
        }
    },

    getMaxSortByUserId: async (userId, parentFolderId) => {
        let entityList = await db.chat
            .where('userId').equals(userId)
            .and(entity => {
                return entity.parentId === parentFolderId;
            })
            .toArray();
        if (entityList == null || entityList.length === 0) {
            return 0;
        }
        let max = entityList[0].sort;
        for (let entity of entityList) {
            if (entity.sort > max) {
                max = entity.sort;
            }
        }
        return max;
    },

    count: async () => {
        return db.chat.count();
    },

    /*
        updateSort: async (entity) => {
            let id = entity.id;
            await db.chat.put({
                id,
                sort: entity.sort
            });
        },

        updateSortAndParentId: async (entity) => {
            let id = entity.id;
            await db.chat.put({
                id,
                parentId: entity.parentId,
                sort: entity.sort
            });
        }
    */

    convertToString: function (data) {
        if (data === undefined || data === null) return null;
        return String(data);
    },


}

export {
    table,
    // bookmarkMapper,
    chatRepository
}

/**
 * chat 库工厂，为适应多房间结构，特设计该方法进行分别处理
 * @type {{getInstance: (function(): {})}}
 */
export const ChatFactory = (function () {
    let instance;
    const ROOM_CHAT_MAP = new Map();

    function createInstance() {
        // 创建实例的逻辑
        return {
            // 属性和方法
        };
    }

    function getDatabase(roomId) {
        if (roomId === undefined || roomId === null || roomId === '') {
            roomId = '1';
        }

        let chatMessage = ROOM_CHAT_MAP.get(roomId);
        if (chatMessage === undefined || chatMessage === null) {
            chatMessage = new ChatMessage(roomId);
            ROOM_CHAT_MAP.set(roomId, chatMessage);
        }
        return chatMessage;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        getChatDatabase: function (roomId) {
            this.getInstance();
            return getDatabase(roomId);
        }
    };
})();

function ChatMessage(roomId) {
    if (roomId === undefined || roomId === null || roomId === '') {
        roomId = '1';
    }

    const db = new Dexie(`Jusic_${roomId}`);
    let SUPPORT_INDEXEDDB = false;
    let CHAT_MESSAGE_EXPIRE_ENABLED = false;
    let CHAT_MESSAGE_MAX_NUMBER = 1000;
    if (window.indexedDB) {
        SUPPORT_INDEXEDDB = true;
    } else {
        SUPPORT_INDEXEDDB = false;
    }

    db.version(1).stores({
        chat: '++id, *createTime, *sessionId, *nickName, *type, *content, *raw, *clientId, *roomId'
    });

    const table = {
        chat: {
            id: null,
            createTime: null,
            sessionId: null,
            nickName: null,
            type: null,
            content: null,
            raw: null,
            clientId: null,
            roomId: null
        }
    }

    this.list = async () => {
        return await db.chat.toArray();
    }

    this.listByUserId = async (userId) => {
        return db.chat.where('userId').equals(userId).toArray();
    }

    this.getById = async (id) => {
        return await db.chat.get(id);
    }

    this.save = async (entity) => {
        // let no = chatRepository.convertToString(generate());
        let gmtC = new Date().getTime();
        await this.deleteOld();

        db.chat.add({
            createTime: gmtC,
            sessionId: entity.sessionId,
            nickName: entity.nickName,
            type: entity.type,
            content: entity.content,
            raw: entity.raw,
            clientId: entity.clientId,
            roomId: entity.roomId
        });
    }

    this.deleteOld = async () => {
        if (!CHAT_MESSAGE_EXPIRE_ENABLED) {
            // message expire policy is not enabled.
            // log.debug("[chat-repository] chat message expire policy is not enabled.")
        } else {
            let count = await this.count();
            let overflowTimes = count - CHAT_MESSAGE_MAX_NUMBER;
            // log.debug(`[chat-repository] delete old data; data count: ${count}, overflow times: ${overflowTimes}`);
            if (overflowTimes > 0) {
                for (let i = 0; i < overflowTimes; i++) {
                    let item = await db.chat.orderBy('id').first();
                    if (item) {
                        await this.deleteById(item.id);
                        // log.debug(`[chat-repository] delete old data; delete time: ${i + 1}; id: ${item.id}`, item);
                    }
                }
            }
        }
    }

    this.deleteById = async (id) => {
        db.chat.delete(id);
    }

    this.load = async (page, size) => {
        if (page === null || size === null) {
            return this.loadAll();
        } else {
            let offset = (page - 1) * size;
            // log.debug(`[db-load]: page: ${page}, size: ${size}, offset: ${offset}`);
            return db.chat.orderBy('id').reverse().offset(offset).limit(size).toArray();
        }
    }

    this.loadAll = async () => {
        return await db.chat.toArray();
    }

    this.loadBeforeId = async function (id, size) {
        if (size === null) size = 10;
        if (id !== undefined && id !== null) {
            // log.debug(`[db-load]: loadBeforeId; id: ${id}, size: ${size}`);
            return db.chat.where('id').below(id).reverse().limit(size).toArray();
            // return db.chat.where('id').above(id).limit(size).toArray();
        } else {
            // log.debug(`[db-load]: loadBeforeId; size: ${size}`);
            return db.chat.orderBy('id').reverse().limit(size).toArray();
        }
    }

    this.getMaxSortByUserId = async function (userId, parentFolderId) {
        let entityList = await db.chat
            .where('userId').equals(userId)
            .and(entity => {
                return entity.parentId === parentFolderId;
            })
            .toArray();
        if (entityList == null || entityList.length === 0) {
            return 0;
        }
        let max = entityList[0].sort;
        for (let entity of entityList) {
            if (entity.sort > max) {
                max = entity.sort;
            }
        }
        return max;
    }

    this.count = async function () {
        return db.chat.count();
    }

    this.convertToString = function (data) {
        if (data === undefined || data === null) return null;
        return String(data);
    }
}
