<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import {finger} from './utils/finger';

export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  async created() {
    // 您不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        this.createFingerprint();
      });
    } else {
      setTimeout(() => {
        this.createFingerprint();
      }, 500);
    }
  },
  methods: {
    // 创建浏览器指纹
    createFingerprint() {
      finger();
    },
  },
};
</script>

<style lang="scss">
    /*@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic';*/
    /*@import url("https://fonts.googleapis.com/css?family=Material+Icons");*/
    @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons");

    * {
        padding: 0;
        margin: 0;
    }

    @import 'assets/css/main';
</style>
